var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"item-container"},[_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"2"}},[_c('p',{staticClass:"topic-text"},[_vm._v(_vm._s(_vm.contentMixtureObject.posts_per_month))])]),_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"6"}},[_c('p',{staticClass:"topic-text"},[_vm._v(_vm._s(_vm.contentMixtureObject.topic))])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"pt-4 pl-4",attrs:{"outlined":"","type":"number","placeholder":"Occurrence","hide-details":"","value":_vm.contentMixtureObject.occurrence,"suffix":"%","rules":[
        _vm.rules.integer(
          _vm.contentMixtureObject.occurrence,
          'Value must be a positive number'
        ),
        _vm.rules.max100,
      ],"min":"0","max":"100"},on:{"change":_vm.changeOccuranceHandler}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }