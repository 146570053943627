<template>
  <div class="container-wrapper" sticky-container>
    <Loader v-if="isLoading"></Loader>
    <v-container v-else>
      <v-form ref="form" v-if="isSuccess" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="9">
            <v-row>
              <v-col cols="2" class="text-center"> Post per month </v-col>
              <v-col cols="6" class="text-center"> Topic </v-col>
              <v-col cols="2" class="text-center"> Occurrence </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" ref="my_refs">
            <v-row>
              <v-col cols="12">
                <v-col cols="4" class="text-center balance"> %Balance </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9">
            <content-mixture-form
              v-for="item in contentMixture.filter((i) => i.is_selected)"
              :key="item.id"
              :content-mixture-object="item"
              @changedOccurance="changedOccuranceHandler"
            />
          </v-col>
          <v-col
            cols="3"
            v-sticky
            sticky-side="both"
            :style="{ maxWidth: getWidth + 'px' }"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  readonly
                  :value="balanceString"
                  placeholder="Balance"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  class="approve-btn"
                  :class="{ 'disabled-approve': !isCanNormalize }"
                  @click="normalizeHandler"
                  :disabled="!isCanNormalize"
                  >Normalize</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-btn
                  class="approve-btn"
                  :class="{ 'disabled-approve': !isCanApprove }"
                  elevation="2"
                  :disabled="!isCanApprove"
                  @click="approveHandler"
                  >Approve</v-btn
                >
              </v-col>
              <v-col cols="12">
                <div class="selected-list">
                  <v-checkbox
                    v-for="item in contentMixture"
                    :key="item.id"
                    :label="item.topic"
                    v-model="item.is_selected"
                    @change="item.occurrence = 0"
                    hide-details
                  ></v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <div class="content-container" v-else>
        <div>
          <img src="@/assets/img/icons/warning-icon.svg" alt="" />

          <h2>Oops!</h2>
          <h3>Somethings went wrong.</h3>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ContentMixtureForm from "@/components/contentMixture/ContentMixtureForm";
import Loader from "@/components/global/Loader";

export default {
  name: "Index",
  components: {
    ContentMixtureForm,
    Loader,
  },
  valid: true,
  data: () => ({
    contentMixture: [],
    isLoading: true,
    isSuccess: true,
    valid: true,
    getWidth: 0,
  }),
  async mounted() {
    await this.fetchMixtureData();
    this.getWidth = this.$refs.my_refs.clientWidth || null;
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    balance() {
      return (
        100 -
        this.contentMixture
          .filter((i) => i.is_selected)
          .map((item) => item.occurrence || 0)
          .reduce((prev, curr) => prev + curr, 0)
      );
    },
    balanceString() {
      return this.balance + "%";
    },
    isCanApprove() {
      return this.balance >= -1 && this.balance <= 1 && this.valid;
    },
    isCanNormalize() {
      return this.valid;
    },
  },
  methods: {
    ...mapActions("contentMixture", ["fetchContentPerMonth", "updateMixture"]),
    changedOccuranceHandler(data) {
      let searchResult = this.contentMixture.find((i) => i.id === data.id);

      if (searchResult) {
        searchResult.occurrence = data.occurrence;
        searchResult.is_changed = true;
      }
    },
    async fetchMixtureData() {
      try {
        this.isLoading = true;
        this.contentMixture = await this.fetchContentPerMonth({
          customerId: this.getCurrentCustomer.id,
          action: "get_topic_mix",
        });
        this.isSuccess = true;
      } catch (e) {
        this.isSuccess = false;
      } finally {
        this.isLoading = false;
      }
    },
    async normalizeHandler() {
      if (this.$refs.form.validate()) {
        this.contentMixture = await this.updateMixture({
          customerId: this.getCurrentCustomer.id,
          action: "normalize_topic_mix",
          occurrence: this.contentMixture,
        });
      }
    },
    async approveHandler() {
      if (this.$refs.form.validate()) {
        this.contentMixture = await this.updateMixture({
          customerId: this.getCurrentCustomer.id,
          action: "approve_topic_mix",
          occurrence: this.contentMixture,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.container-wrapper {
  width: 100%;
  display: flex;
  padding-right: 132px;
  padding-left: 15px;
  transition: all 0.3s ease;
  padding-bottom: 20px;

  &.active {
    padding-right: 15px;
    transition: all 0.3s ease;
  }
  .approve-btn {
    background: rgba(126, 86, 137, 0.2) !important;
    border-radius: 16px !important;
    height: 36px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: none !important;
    letter-spacing: normal !important;
    line-height: 140%;
    padding: 0 16px !important;
    color: $color-violet;
    box-shadow: none !important;
    &:hover {
      background: rgba(126, 86, 137, 0.4);
    }
    &[disabled] {
      background-color: $color-red !important;
      color: $color-white !important;
      cursor: pointer;
      &:hover {
        background-color: rgba(238, 35, 35, 0.4) !important;
      }
    }
  }
  .balance {
    padding-top: unset !important;
  }
}
.content-container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  div {
    align-self: center;
    h2 {
      font-size: 40px;
      text-align: center;
    }
    img {
      height: 200px;
      width: 200px;
    }
  }
}

.selected-list {
  height: 300px;
  border: 2px solid;
  overflow-y: auto;
  overflow-x: hidden;
  .v-input {
    .v-messages {
      display: none !important;
    }
    &.v-input--selection-controls {
      margin-top: 10px !important;
    }
    ::v-deep {
      .v-label {
        font-size: 15px !important;
        line-height: 16px;
      }
    }
  }
}
.vue-sticky-el {
  &.top-sticky {
    width: 100% !important;
    /*transform: translateX(-300px) !important;*/
  }
  &.bottom-sticky {
    position: static !important;
  }
}
</style>
