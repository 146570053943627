<template>
  <v-row class="item-container">
    <v-col class="align-self-center" cols="2">
      <p class="topic-text">{{ contentMixtureObject.posts_per_month }}</p>
    </v-col>
    <v-col class="align-self-center" cols="6">
      <p class="topic-text">{{ contentMixtureObject.topic }}</p>
    </v-col>
    <v-col cols="2">
      <v-text-field
        class="pt-4 pl-4"
        outlined
        type="number"
        placeholder="Occurrence"
        hide-details
        :value="contentMixtureObject.occurrence"
        suffix="%"
        :rules="[
          rules.integer(
            contentMixtureObject.occurrence,
            'Value must be a positive number'
          ),
          rules.max100,
        ]"
        min="0"
        max="100"
        @change="changeOccuranceHandler"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import validationRules from "@/shared/validationRules";

import ContentMixture from "@/models/ContentMixture";

export default {
  name: "ContentMixtureForm",
  mixins: [validationRules],
  props: {
    contentMixtureObject: {
      type: Object,
      default: () => new ContentMixture(),
    },
  },
  methods: {
    changeOccuranceHandler(newValue) {
      this.$emit("changedOccurance", {
        id: this.contentMixtureObject.id,
        occurrence: parseInt(newValue),
      });
    },
  },
};
</script>

<style scoped lang="scss">
.item-container {
  height: 80px;
}
.topic-text {
  font-size: 17px;
  text-align: center;
}
</style>
